<template>
  <div class="select-date-range">
    <button
      class="select-date-range__button"
      :class="{
        'select-date-range__button--locked': isLocked
      }"
      :disabled="isLocked"
      @click="event => openChoices(event)"
    >
      <div class="button__selection">
        <i
          class="selection__date-icon"
          :class="
            selectedChoice.isFuture ? `icon-binoculars` : `icon-date-range`
          "
        />
        <p class="selection__text">
          {{ selectedChoice.label }}
        </p>
      </div>
      <i
        v-if="choices.length > 0 && !isLocked"
        class="button__arrow-icon icon-double-arrow"
      />
      <i
        v-if="isLocked"
        class="button__lock-icon icon-lock"
      />
    </button>

    <Teleport
      v-if="areChoicesDisplayed"
      to="#action"
    >
      <ReflectOverlay @cancel="closeChoices">
        <div
          class="select-date-range__choices-tooltip"
          :style="{
            top: choicesTooltipPosition!.y + 'px',
            left: choicesTooltipPosition!.x + 'px'
          }"
          @click.stop=""
        >
          <p
            v-if="canSelectCustomDateRange"
            class="choices-tooltip__title"
          >
            {{ $t('dashboard.filters.time.types.dynamic') }}
          </p>
          <button
            v-for="choice in choices"
            :key="choice.label"
            class="choices-tooltip__element"
            :class="
              isChoiceSelected(choice) && 'choices-tooltip__element--selected'
            "
            @click="selectChoice(choice)"
          >
            <i
              class="element__period-icon"
              :class="choice.isFuture ? `icon-binoculars` : `icon-date-range`"
            />
            <span class="element__label">
              {{ choice.label }}
            </span>
            <i
              v-if="isChoiceSelected(choice)"
              class="element__success-icon"
              :class="`icon-success`"
            />
          </button>
          <ReflectSelectDateRangeCustomOption
            v-if="canSelectCustomDateRange"
            :is-selected="isCustomChoiceSelected"
            :date-range="dateRange"
            @set-date-range="setDateRange"
          />
        </div>
      </ReflectOverlay>
    </Teleport>
  </div>
</template>

<script lang="ts">
import { type DateRangeOption } from '~/types/dateRange'

export default {
  name: 'SelectDateRange',
  props: {
    choices: {
      type: Array as PropType<DateRangeOption[]>,
      default: () => []
    },
    selectedChoice: {
      type: Object as PropType<DateRangeOption>,
      required: true
    },
    dateRange: {
      type: Array as PropType<string[]>,
      default: () => []
    },
    canSelectCustomDateRange: {
      type: Boolean,
      default: false
    },
    isLocked: {
      type: Boolean,
      default: false
    }
  },
  emits: ['set-choice', 'set-date-range'],
  data(): {
    areChoicesDisplayed: boolean
    choicesTooltipPosition: { x: number; y: number } | undefined
  } {
    return {
      areChoicesDisplayed: false,
      choicesTooltipPosition: undefined
    }
  },
  computed: {
    isCustomChoiceSelected() {
      const choicesLabels = this.choices.map(choice => choice.label)
      return !choicesLabels.includes(this.selectedChoice.label)
    }
  },
  methods: {
    setDateRange(newDateRange: string[]) {
      this.$emit('set-date-range', newDateRange)
      this.areChoicesDisplayed = false
    },
    selectChoice(newChoice: DateRangeOption) {
      if (newChoice.label !== this.selectedChoice.label) {
        this.$emit('set-choice', newChoice)
      }
    },
    openChoices(event: Event) {
      if (this.choices.length === 0) return

      const position = (
        event.currentTarget as HTMLDivElement
      ).getBoundingClientRect()
      this.choicesTooltipPosition = { x: position.left, y: position.bottom }
      this.areChoicesDisplayed = true
    },
    closeChoices() {
      this.areChoicesDisplayed = false
    },
    isChoiceSelected(choice: DateRangeOption) {
      return choice.label === this.selectedChoice.label
    }
  }
}
</script>

<style lang="scss" scoped>
$select-date-range-width: 252px;

.select-date-range {
  position: relative;
  width: $select-date-range-width;
  user-select: none;

  &__button {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 0 $margin-regular;
    border-radius: 20px;
    background-color: $bg-secondary;
    @include block-shadow;
    cursor: pointer;
    height: 40px;

    &--locked {
      cursor: default;
    }
  }

  .button {
    &__selection {
      display: flex;
      align-items: center;
      flex: 1;

      .selection {
        &__date-icon {
          @include font-icon;
          @include font-size($font-size-intermediate);
          margin-right: 4px;
          color: $text-primary;
        }

        &__text {
          @include font-text;
          @include font-size($font-size-regular);
          color: $text-primary;
        }
      }
    }

    &__arrow-icon {
      @include font-icon;
      @include font-size($font-size-intermediate);
      color: $link-secondary;
    }

    &__lock-icon {
      @include font-icon;
      @include font-size(14px);
      color: $text-secondary;
    }
  }

  &__choices-tooltip {
    position: fixed;
    width: $select-date-range-width;
    padding: $margin-small $margin-regular;
    margin-top: $margin-mini;
    display: flex;
    flex-direction: column;
    background-color: $bg-secondary;
    border-radius: 24px;
    cursor: default;
    @include block-shadow;

    .choices-tooltip {
      &__title {
        @include font-text;
        @include font-size($font-size-mini);
        color: $text-tertiary;
        margin-bottom: 4px;

        &:not(:first-of-type) {
          margin-top: $margin-small;
        }
      }

      &__element {
        display: flex;
        align-items: center;
        padding: 4px 0;
        color: $link-secondary;
        cursor: pointer;

        &:not(:last-child) {
          margin-bottom: 4px;
        }

        &--selected,
        &:hover {
          color: $text-primary;
        }

        .element {
          &__period-icon {
            @include font-icon;
            @include font-size($font-size-intermediate);
            margin-right: 4px;
          }

          &__label {
            @include font-text;
            @include font-size($font-size-regular);
          }

          &__success-icon {
            @include font-icon;
            @include font-size(18px);
            margin-left: auto;
          }
        }
      }
    }
  }
}
</style>
